<div class="row my-5">
    <div class="col-md-3">
        <div class="row mr-1">
            <div class="col-md-12 dashboard-tile-blue py-2 px-4">
                <button class="btn" (click)="navigate('/trabajadores')" [disabled]="!isCompanySelected">
                    <img alt="icon" class="trabajadores-icon" src="assets\images\trabajadores.svg" width="80"
                        height="80">
                    <span class="ml-3 text-left">Trabajadores</span>
                </button>
            </div>
        </div>
        <div class="row mr-1">
            <div class="col-md-12 dashboard-tile-grey py-2 px-4" style="height:7em;">
                <div class="w-100">
                </div>
            </div>

        </div>
        <div class="row mr-1">
            <div class="col-md-12 dashboard-tile-blue py-2 px-4">
                <button class="btn w-100  d-flex" (click)="navigate('/compdoc')" [disabled]="!isCompanySelected">
                    <img alt="icon" class="horizontal-tile-icon" src="assets\images\formacion_empresa.svg">
                    <span class="ml-3 text-left">Documentación Empresa</span>
                </button>

            </div>
        </div>
        <div class="row mr-1">
            <div class="col-md-12 dashboard-tile-blue py-2 px-4">
                <button class="btn w-100  d-flex" (click)="powerBiRedirect('/powerbi')" [disabled]="!isCompanySelected">
                    <img alt="icon" class="horizontal-tile-icon" src="assets\images\icon_powerbi.svg">
                    <span class="ml-3 text-left"> Informes de BI </span>
                </button>
            </div>

        </div>
    </div>

    <div class="col-md-3">
        <div class="row mr-1">
            <div class="col-md-12 dashboard-tile-blue py-2 px-4">
                <button class="btn w-100  d-flex" (click)="navigate('/healthreports')" [disabled]="!isCompanySelected">
                    <img alt="icon" class="horizontal-tile-icon" src="assets\images\salud.svg">
                    <span class="ml-3 text-left">Certificados de Aptitud</span>
                </button>
            </div>

        </div>
        <div class="row mr-1">
            <div class="col-md-12 dashboard-tile-blue py-2 px-4">
                <button class="btn w-100  d-flex" (click)="navigate('/trncert')" [disabled]="!isCompanySelected">
                    <img alt="icon" class="horizontal-tile-icon" src="assets\images\formacion_empresa.svg">
                    <span class="ml-3 text-left">Certificados de Formaciones</span>
                </button>

            </div>
        </div>
        <div class="row mr-1">
            <div class="col-md-12 dashboard-tile-blue py-2 px-4">
                <button class="btn w-100  d-flex" (click)="navigate('/procedures')" [disabled]="!isCompanySelected">
                    <img alt="icon" class="horizontal-tile-icon" src="assets\images\formacion_empresa.svg">
                    <span class="ml-3 text-left">Procedimientos</span>
                </button>

            </div>
        </div>
        <div class="row mr-1">
            <div class="col-md-4 ml-3">
            </div>
        </div>
    </div>
    <div class="col-md-6" *ngIf="isReportShow">
        <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass">
        </powerbi-report>
    </div>
</div>
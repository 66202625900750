
<form name="trabajadoresForm" [formGroup]="inactiveForm">
  <div class="row">
      <div class="col-md-3 d-flex s-m-t-10">
          <input type="text" placeholder="Trabajador" name="search" class="search-input w-100" formControlName="trabajador" (keyup)="onKeyUpTrabajadorInactive($event)">
          <button type="submit" class="search-icon-btn" (click)="onSearchTrabajador()">
              <span class="fa fa-search"></span>
          </button>
      </div>
      <div class="col-md-3 col-lg-3 s-m-t-10">
          <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
              [items]="filterCenters" id="filter-center" name="filter-center" [clearable]="true"
              [virtualScroll]="true" bindLabel="nombre_centro" bindValue="id" placeholder="Centro de Trabajo"
              formControlName="centro" (change)="selectCentro()">
          </ng-select>
      </div>
      <div class="col-md-6 s-m-t-10 text-right mb-2">
          <div class="btn-group" role="group" aria-label="Basic example">
              <!-- <button type="button" class="btn-primary mr-2" (click)="getTrabajadore()">Añadir Trabajador</button> -->
              <button type="button" class="btn-primary" (click)="downloadExcel()">Descargar Excel</button>
          </div>                            
      </div>
  </div>
</form> 

  <div class="row">
    <div class="col-md-12 d-flex s-m-t-10">
        <table class="table">
            <caption></caption>
            <thead class="thead-light">
                <tr>
                    <!-- <th></th> -->
                    <th>NIF</th>
                    <th>Nombre Trabajador</th>
                    <th>Telefono</th>
                    <th>E-Mail</th>
                    <th>Activar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of trabInactiveList| paginate: inPaginationConfig">
                    <!-- <td>
                        <a class="pb-2">
                            <span class="fa fa-edit tbl-row-action"></span>
                        </a>
                    </td> -->
                    <td>{{item.trabajador_nif}}</td>
                    <td>{{item.trabajador_txt}}</td>
                    <td>{{item.trabajador_telef}}</td>
                    <td>{{item.trabajador_email}}</td>
                    <td>
                        <a class="pb-2" (click)="onAddTrabajadore(item.trabajador_id)">
                            <span class="fa fa-plus tbl-row-action"></span>
                        </a>
                    </td>
                </tr>
            </tbody>                           
        </table>
  
    </div>                                    
  </div>
  
  <div class="row">
    <!-- pagination -->
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pagination-div">
        <div class="d-flex pl-0">
            <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" id="pending-inactive-list-pagination"
                (pageChange)="pendingPageChangedInactive($event)" (pageBoundsCorrection)="pendingPageChangedInactive($event)">
            </pagination-controls>
            <span class="pagination-font float-left pt-1"> Hay {{ inPaginationConfig.totalItems }} registros
                encontrados</span>
        </div>
    </div>
  </div>
           

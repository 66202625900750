<app-spinner *ngIf="isLoading"></app-spinner>
<div class="agendas-breadcrumb">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">Analitica BI</li>
    </ol>
  </nav>
</div>
<div style="height: 100vh !important" *ngIf="embedConfigObs | async as embedConfig">
  <powerbi-report [embedConfig]="embedConfig"  [cssClassName]="reportClass" ></powerbi-report>
</div>


<div class="modal-body">
  <form [formGroup]="personaForm" id="personaForm">
    <div class="row pl-3 pb-3">
      <div class="col-md-10 text-left pl-0">
        <h1 id="activity-modal" class="modal-title popup-title-f-s font-weight-bold">
          Datos Personales
        </h1>
      </div>
      <div class="col-md-2">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="mb-0">Empresa</label>
              <input type="text" class="form-control" formControlName="empresa_id" readonly />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="mb-0">Nombre</label>
              <input type="text" class="form-control" formControlName="nombre"
                [ngClass]="{ 'is-valid': personaFormControl.nombre.valid,
                'is-invalid': (personaFormControl.nombre.dirty || personaFormControl.nombre.touched ) && personaFormControl.nombre.invalid}" />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="mb-0">Apellidos</label>
              <input type="text" class="form-control" formControlName="apellidos"
                [ngClass]="{'is-valid': personaFormControl.apellidos.valid,
                'is-invalid': (personaFormControl.apellidos.dirty || personaFormControl.apellidos.touched) && personaFormControl.apellidos.invalid }" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group border" [ngClass]="{'invalid-border': seleCentorList.length == 0 && personaForm.valid }">
          <label class="mb-0">Centro de trabajo</label>
          <div class="row">
            <div class="col-1 mt-2">
              <a class="pb-2" (click)="addCentrotrabajo()">
                <span class="fa fa-plus"></span>
              </a>
            </div>
            <div class="col-11">
              <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                [items]="filterCenters" id="filter-center" name="filter-center" [clearable]="true"
                [virtualScroll]="true" bindLabel="nombre_centro" bindValue="id" placeholder="Centro de Trabajo"
                formControlName="centro_trabajo">
              </ng-select>
            </div>
          </div>

          <ul class="list-group mt-2">
            <li class="list-group-item" *ngFor="let item of seleCentorList">
              {{item.nombre_centro}}
              <a class="pb-2" (click)="removeCentrotrabajo(item)">
                <span class="fa fa-times tbl-row-cancel"></span>
              </a>
            </li>
          </ul>

        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">NIF</label>
          <input type="text" formControlName="nip" class="form-control form-control-sm" id="nip" (keypress)="omitSpecialChar($event)" (keydown.space)="$event.preventDefault()"
            [ngClass]="{'is-valid': personaFormControl.nip.valid,
                             'is-invalid': (personaFormControl.nip.dirty || personaFormControl.nip.touched ) && personaFormControl.nip.invalid }" />
          <div *ngIf="personaFormControl.nip?.errors" class="invalid-feedback">
            NIF ERRÓNEO
          </div>

          <div *ngIf="!personaFormControl.nip?.errors" class="valid-feedback">
            NIF OK
          </div>

        </div>
      </div>
      <div class="col-md-3">
        <div class="form-check" style="padding-top: 21px">
          <input class="form-check-input" type="checkbox" [checked]="false" value="chkPassport" id="chkPassport"
            [(ngModel)]="chkPassport" disabled="disabled" (change)="onChangeChkPassport($event)"
            [ngModelOptions]="{ standalone: true }" />
          <label for="chkPassport" style="font-weight: 800">
            Es un pasaporte
          </label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">Fecha Nacimiento</label>
          <input [owlDateTime]="filterFechanacimiento" [owlDateTimeTrigger]="filterFechanacimiento" type="text"
            class="form-control form-control-sm date-field" placeholder="DD/MM/YYYY" formControlName="fecha_nacimiento"
            [class.is-invalid]="
                    (personaFormControl.fecha_nacimiento.dirty || personaFormControl.fecha_nacimiento.touched) &&
                    personaFormControl.fecha_nacimiento.invalid
                  ">
          <owl-date-time #filterFechanacimiento [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0"> Género </label>
          <ng-select [clearAllText]="'limpiar todo'" #select (close)="select.blur()" loadingText="Cargando..."
            notFoundText="No hay resultados" bindLabel="name" formControlName="genero" bindValue="gid"
            [items]="genderList" placeholder="Seleccione" [class.ng-invalid]=" (personaFormControl.genero.dirty ||
                          personaFormControl.genero.touched) && personaFormControl.genero.invalid
                    ">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">Idioma</label>
          <ng-select [clearAllText]="'limpiar todo'" #select (close)="select.blur()" loadingText="Cargando..."
            notFoundText="No hay resultados" bindLabel="nombre" formControlName="language_id" bindValue="id"
            [items]="languageList" placeholder="Seleccione" [class.ng-invalid]=" (personaFormControl.language_id.dirty ||
                          personaFormControl.language_id.touched) && personaFormControl.language_id.invalid
                    ">
          </ng-select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">Tlf Empresa</label>
          <input type="text" class="form-control form-control-sm" formControlName="tel2" [ngClass]="{ 
            'is-valid': personaFormControl.tel2.valid,
            'is-invalid': personaFormControl.tel2.invalid
          }" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-0">Email Empresa</label>
          <input type="email" class="form-control form-control-sm" formControlName="email2" [ngClass]="{
            'is-valid': personaFormControl.email2.valid,
            'is-invalid': personaFormControl.email2.invalid
          }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-0">Notas</label>
          <textarea class="form-control form-control-sm" formControlName="comentarios"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 text-right mt-2">
        <button class="btn btn-danger mr-3" *ngIf="iseliminar" (click)="deletePerson()">Eliminar</button>
      </div>

      <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10 text-right mt-2">
        <button class="btn btn-secondary mr-3" type="button" (click)="modal.dismiss()"> Cancelar</button>
        <button class="btn btn-primary" type="button" 
          (click)="savePerson()">Guardar</button>
      </div>
    </div>
  </form>
</div>

  <form name="trabajadoresForm" [formGroup]="activeForm">
    <div class="row">
        <div class="col-md-3 d-flex s-m-t-10">
            <input type="text" placeholder="Trabajador" name="search" class="search-input w-100" formControlName="trabajador" (keyup)="onKeyUpTrabajadorActive($event)">
            <button type="submit" class="search-icon-btn" (click)="onSearchTrabajador()">
                <span class="fa fa-search"></span>
            </button>
        </div>
        <div class="col-md-3 col-lg-3 s-m-t-10">
            <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                [items]="filterCenters" id="filter-center" name="filter-center" [clearable]="true"
                [virtualScroll]="true" bindLabel="nombre_centro" bindValue="id_centro" placeholder="Centro de Trabajo"
                formControlName="centro" (change)="selectCentro()">
            </ng-select>
        </div>
        <div class="col-md-3 s-m-t-10">
            <div class="row">
                <div class="form-group form-check form-check-inline border border-secondar">
                
                    <div class="checkbox-inline mr-2">
                        <input type="radio" class="form-check-input" name="checkaptos" id="aptos" formControlName="checkaptos" value="aptos"
                        (change)="changeAptos()">
                        <label for="aptos" class="form-check-label"> Aptitud Vigente </label>
                    </div>
                    <div class="checkbox-inline mr-2 ml-2">
                        <input type="radio" class="form-check-input" name="checkaptos" id="noaptos" formControlName="checkaptos" value="noaptos"
                        (change)="changeAptos()">
                        <label for="noaptos" class="form-check-label"> No Aptos </label>
                    </div>
                    <div class="checkbox-inline mr-2 ml-2">
                        <input type="radio" class="form-check-input" name="checkaptos" id="todos" formControlName="checkaptos" value="todos"
                        (change)="changeAptos()">
                        <label for="todos" class="form-check-label"> TODOS </label> <span> </span>
                    </div>
                </div>
            </div>            
        </div>
        <div class="col-md-3 s-m-t-10 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
                <!-- <button type="button" class="btn-primary mr-2" (click)="newTrabajadore()">Añadir Trabajador</button> -->
                <button type="button" class="btn-primary" (click)="downloadExcel()">Descargar Excel</button>
            </div>                            
        </div>
    </div>
    
    <div class="row mt-2">
      <div class="col-md-12 d-flex s-m-t-10">
        <table class="table tbl-trabajadore">
            <caption></caption>
            <thead class="thead-light">
                <tr>
                    <!-- <th></th> -->
                    <th>NIF</th>
                    <th>Nombre Trabajador</th>
                    <th>Telefono</th>
                    <th>E-Mail</th>
                    <th>Aptitud</th>
                    <th>Caducidad</th>
                    <th>Próxima cita agendada</th>
                    <th>Certificados</th>
                    <th>Inactivar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of trabActiveList | paginate: paginationActiveConfig;">
                    <!-- <td>
                        <a class="pb-2" (click)="onEditTrabajadore(item)">
                            <span class="fa fa-edit tbl-row-action"></span>
                        </a>
                    </td> -->
                    <td>{{item.trabajador_nif}}</td>
                    <td>{{item.trabajador_txt}}</td>
                    <td>{{item.trabajador_telef}}</td>
                    <td>{{item.trabajador_email}}</td>
                    <td>
                        <a *ngIf="item.aptitud_tiene" class="btn btn-primary btn-icon" (click)="onClickAPT(item.aptitud_doc)">
                            APT.<span class="fa fa-sort-desc" aria-hidden="true"></span> 
                        </a>
                    </td>
                    <td> {{ _commonUiService.dateStringFormat(item.aptitud_caducidad)}} </td>
                    <td class="text-gray" *ngIf="item.siguiente_activo" (click)="onEditProximacita(item)">
                            <i class="fa fa-edit tbl-row-action">  </i>                           
                            {{ _commonUiService.dateStringFormat(item.siguiente_cita)}} 
                    </td>
                    <td *ngIf="!item.siguiente_activo">
                        {{ _commonUiService.dateStringFormat(item.siguiente_cita)}}
                    </td>
                    <td>
                        <a class="btn btn-primary btn-icon" (click)="getCertificates(item)">
                            VER 
                        </a>
                    </td>
                    <td>
                        <a class="pb-2" (click)="onCancelTrabajadore(item.trabajador_id)">
                            <span class="fa fa-times tbl-row-cancel"></span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    
      </div>                                    
    </div>
    
    <div class="row">
      <!-- pagination -->
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pagination-div">
          <div class="d-flex pl-0">
              <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" id="pending-active-list-pagination" 
                  (pageChange)="pendingPageChanged($event)" (pageBoundsCorrection)="pendingPageChanged($event)">
              </pagination-controls>
              <span class="pagination-font float-left pt-1"> Hay {{ paginationActiveConfig.totalItems }} registros
                  encontrados</span>
          </div>
      </div>
    </div>
  </form>     

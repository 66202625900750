import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProceduresService } from 'src/app/core/procedures.service';
import { ProceduresDownloadDocRequest, ProceduresItem, ProceduresRegenerateDocRequest } from 'src/app/model/procedures-list-response';

@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html',
  styleUrls: ['./procedures.component.scss']
})
export class ProceduresComponent implements OnInit {
  timerSubscription: Subscription;
  ProcudresList: ProceduresItem[] = [];
  loadingId: string = "";
  donloadLoadingId: string = "";
  company: any;

  constructor(private proceduresService: ProceduresService) { 
    this.company = JSON.parse(localStorage.getItem('company'));
  };

  ngOnInit(): void {
    this.getProceduresDPList();
    } 

  //get procedures List
  getProceduresDPList() {
    let companyData = {
      empresa_id : this.company.empresa_id
    }

    this.proceduresService.getEmpresaDPsList(companyData).subscribe(res => {
      this.ProcudresList = res.data.sort((a, b) => { return a.doc_maestro_txt < b.doc_maestro_txt ? 1 : -1 });
      //tempery- meanwhile fix the update response correct
      this.donloadLoadingId = "";
    })
  }

  //click download
  onClickDescargar(data: ProceduresItem) {
    let body: ProceduresDownloadDocRequest = {
      id_documento: data.id_documento
    }
    this.proceduresService.downloadDocumnetById(body).subscribe(res => {
      if (res.data) {
        this.convertBase64ToDownloadPDF(res.data.fichero, res.data.nombre);
      }
    });
  }

  //click re generate
  onClickGenerar(data: ProceduresItem) {
    this.loadingId = data.id_doc_maestro;
    //tempery- meanwhile fix the update response correct
    this.donloadLoadingId = data.id_doc_maestro;
    let body: ProceduresRegenerateDocRequest = {
      id_documento: data.id_documento,
      id_doc_maestro: data.id_doc_maestro
    }
    this.proceduresService.GenDPDocument(body).subscribe((res) => {
      let generatedDoc: ProceduresItem[] = [res.data];
      //temperary use
      if (res.data) {
        this.getProceduresDPList();
      }
      //temperary-comment for meanwhile fix the api
      this.ProcudresList = this.ProcudresList.map(obj1 => generatedDoc.find(obj2 => obj2.id_documento == obj1.id_documento) || obj1);
      this.loadingId = "";
    });
  }

  //pdf download function
  convertBase64ToDownloadPDF(base64String, fileName: string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    if (!fileName.includes(".pdf")) {
      link.download = `${fileName}.pdf`;
    } else {
      link.download = fileName;
    }
    link.click();
  }

}

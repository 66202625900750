<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<div>
    <div class="row header-title">
        <div class="col-md-10 col-lg-10 title-line s-w-70">
            <h5> Documentación Empresa </h5>
        </div>
        <div class="col-md-2 col-lg-2 s-w-30">
            <a [routerLink]="['../pageindex']">Volver</a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <nav class="add-exception-tabs">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="nav-directorios-tab" data-toggle="tab" href="#nav-directorios" (click)="selectTab(1)"
                            role="tab" aria-controls="nav-agregar" aria-selected="true"> Directorios
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ml-2" id="nav-documentos-tab" data-toggle="tab" href="#nav-documentos" (click)="selectTab(2)"
                            role="tab" aria-controls="nav-copiar" aria-selected="false"> Búsqueda avanzada
                        </a>
                    </li>
                </ul>
            </nav>

            <div class="card card-tabs">
                <div id="nav-tabContent" class="tab-content">
                    <div id="nav-directorios" class="tab-pane p-3 fade show active" role="tabpanel" aria-labelledby="nav-directorios-tab">
                        <div class="form-group">
                            <form [formGroup]="directriesForm" name="trainingCertificateFilterForm" #f="ngForm" novalidate>
                                <div class="form-group row">
                                    <label class="col-lg-3 col-md-12 col-form-label">Documento</label>
                                    <div class="col-lg-8 col-md-12">
                                        <input type="text" class="form-control form-control-sm" placeholder="Nombre Documento" formControlName="fDocumentName">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Fecha</label>
                                    <div class="col-md-4">
                                        <input [owlDateTime]="filterDesdeDatePicker" [owlDateTimeTrigger]="filterDesdeDatePicker" type="text"
                                            class="form-control form-control-sm" placeholder="Inicio" formControlName="filterDesdeDate"
                                            [ngClass]="{'border-danger': !directriesForm.get('filterDesdeDate').valid && directriesForm.get('filterDesdeDate').touched}">
                                        <span class="text-danger" *ngIf="!directriesForm.get('filterDesdeDate').valid && directriesForm.get('filterDesdeDate').touched">
                                            Formato de fecha incorrecto (dd/mm/yyyy)
                                        </span>
                                        <owl-date-time #filterDesdeDatePicker [pickerType]="'calendar'"></owl-date-time>
                                    </div>
                                    <div class="col-md-4">
                                        <input [owlDateTime]="filterHastaDatePicker" [owlDateTimeTrigger]="filterHastaDatePicker" type="text"
                                            class="form-control form-control-sm" placeholder="Fin" formControlName="filterHastaDate"
                                            [ngClass]="{'border-danger': !directriesForm.get('filterHastaDate').valid && directriesForm.get('filterHastaDate').touched}">
                                        <span class="text-danger" *ngIf="!directriesForm.get('filterHastaDate').valid && directriesForm.get('filterHastaDate').touched">
                                            Formato de fecha incorrecto (dd/mm/yyyy)
                                        </span>
                                        <owl-date-time #filterHastaDatePicker [pickerType]="'calendar'"></owl-date-time>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-7 ml-4">
                                        <div class="checkbox-inline">
                                            <input type="checkbox" class="form-check-input" name="fshowDocument" id="fshowDocument" formControlName="fshowDocument">
                                            <label for="aptos" (change)="onFilterCheckChange($event)" class="form-check-label"> Mostrar documentos válidos
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <button type="button" class="btn btn-primary btn-sm float-right" (click)="onFilterDocument()">Filtrar</button>
                                    </div>
                                </div>
                                <div id="scroll-y" class="p-2 tree-view-container">
                                    <div class="col-lg-12 col-md-12 d-flex mb-2 mt-2">
                                        <app-company-tree [treeViewModel]="treeViewModel" (branchSelected)="setSelectedBranchId($event)"></app-company-tree>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div id="nav-documentos" class="tab-pane p-3 fade show" role="tabpanel"
                        aria-labelledby="nav-documentos-tab">
                        <div class="form-group">
                            <form [formGroup]="AdSearchForm" name="advanceFilterForm" #f="ngForm" novalidate>
                                <div class="form-group row">
                                    <label class="col-lg-3 col-md-12 col-form-label">Documento</label>
                                    <div class="col-lg-8 col-md-12">
                                        <input type="text" class="form-control form-control-sm" placeholder="Nombre Documento" formControlName="aDocumentName">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Fecha</label>
                                    <div class="col-md-4">
                                        <input [owlDateTime]="adfilterDesdeDatePicker" [owlDateTimeTrigger]="adfilterDesdeDatePicker" type="text"
                                            class="form-control form-control-sm" placeholder="Inicio" formControlName="aFilterDesdeDate"
                                            [ngClass]="{'border-danger': !AdSearchForm.get('aFilterDesdeDate').valid && AdSearchForm.get('aFilterDesdeDate').touched}">
                                        <span class="text-danger" *ngIf="!AdSearchForm.get('aFilterDesdeDate').valid && AdSearchForm.get('aFilterDesdeDate').touched">
                                            Formato de fecha incorrecto (dd/mm/yyyy)
                                        </span>
                                        <owl-date-time #adfilterDesdeDatePicker [pickerType]="'calendar'"></owl-date-time>
                                    </div>
                                    <div class="col-md-4">
                                        <input [owlDateTime]="advfilterHastaDatePicker" [owlDateTimeTrigger]="advfilterHastaDatePicker" type="text"
                                            class="form-control form-control-sm" placeholder="Fin" formControlName="aFilterHastaDate"
                                            [ngClass]="{'border-danger': !AdSearchForm.get('aFilterHastaDate').valid && AdSearchForm.get('aFilterHastaDate').touched}">
                                        <span class="text-danger" *ngIf="!AdSearchForm.get('aFilterHastaDate').valid && AdSearchForm.get('aFilterHastaDate').touched">
                                            Formato de fecha incorrecto (dd/mm/yyyy)
                                        </span>
                                        <owl-date-time #advfilterHastaDatePicker [pickerType]="'calendar'"></owl-date-time>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-7">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="aShowDocument" formControlName="aShowDocument" [checked]="false"  
                                                    (change)="onAdvanceCheckChange($event)" [(ngModel)]="this.advanceFilterItem.docs_validos">
                                            <label for="aptos" class="form-check-label"> Mostrar documentos válidos
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-3 col-md-12 col-form-label">Centro de Trabajo</label>
                                    <div class="col-lg-8">                                        
                                        <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                                            [items]="filterCenters" id="aCenterName" name="aCenterName" [clearable]="true"
                                            [virtualScroll]="true" bindLabel="nombre_centro" bindValue="id" placeholder="Centro de Trabajo"
                                            formControlName="aCenterName" (change)="selectCenter($event)" (clear)="onCloseCentro()">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="form-group row">                                    
                                    <label class="col-lg-3 col-md-12 col-form-label">Trabajador</label>
                                    <div class="col-lg-8">
                                        <ng-select #selectTrabaja [clearAllText]="'limpiar todo'" [virtualScroll]="true" (close)="selectTrabaja.blur()"
                                        loadingText="Cargando..." notFoundText="No hay resultados" id="aEmployeeName" name="aEmployeeName" bindLabel="label"
                                        bindValue="value" placeholder="Trabajador" [items]="filterEmployees" formControlName="aEmployeeName" 
                                        (keyup)="onKeyUpTrabajadore($event)" (scrollToEnd)="onScrollTrabajadore()" (clear)="onCloseTrabajadore()"
                                        (change)="selectTrabajadore($event)" (change)="selectTrabajadore($event)">
                                      </ng-select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-3 col-md-12 col-form-label">Tipo Documento </label>
                                    <div class="col-lg-8">                                        
                                        <ng-select #selectDocType (close)="selectDocType.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                                            [items]="documentTypeList" id="aDocumentType" name="aDocumentType" [clearable]="true"
                                            [virtualScroll]="true" bindLabel="label" bindValue="value" placeholder="Tipo Documento"
                                            formControlName="aDocumentType" (keyup)="onKeyUpTipo($event)" (scrollToEnd)="onScrollTipo()" (clear)="onCloseTipo()"
                                            (change)="selectTipo($event)">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="offset-8 col-md-3">
                                        <button type="button" class="btn btn-primary btn-sm float-right" (click)="onFilterDocument()">Filtrar</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" (click)="onChangeOrder(queryTypes[0])">
                            Nombre Documento <span class="fa arrow-icon-styles" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypes[0].direction === 'DESC', 
                            'fa-sort-asc': queryTypes[0].direction === 'ASC' }"></span>
                        </th>
                        <th scope="col" (click)="onChangeOrder(queryTypes[1])">
                            Fecha <span class="fa" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypes[1].direction === 'DESC', 
                            'fa-sort-asc': queryTypes[1].direction === 'ASC' }"></span>
                        </th>
                        <th scope="col" (click)="onChangeOrder(queryTypes[2])">
                            Directorio <span class="fa" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypes[2].direction === 'DESC', 
                            'fa-sort-asc': queryTypes[2].direction === 'ASC' }"></span>
                        </th>
                        <th scope="col" class="w-5">Descargar</th>
                        <th scope="col" class="w-5">Editar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let docObj of documents | paginate : paginationConfig">
                        <td>{{docObj.name_archivo}}</td>
                        <td>{{_commonUiService.dateStringFormat(docObj.date_archivo)}}</td>
                        <td>{{docObj.path }}</td>
                        <td class="text-center">
                            <button type="button" class="btn-primary down-arrow"
                                (click)="getDocumentoFile(docObj.id_archivo)">
                                <span class="fa fa-sort-desc" aria-hidden="true"></span>
                            </button>
                        </td>
                        <td>
                            <a class="pb-2" (click)="editDocumentFile(docObj)">
                                <span class="fa fa-edit tbl-row-action"> </span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row pagination-div">
                <div class="col-md-9 d-flex">
                    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente"
                        (pageChange)="pendingPageChanged($event)" (pageBoundsCorrection)="pendingPageChanged($event)">
                    </pagination-controls>
                    <span class="float-left doc-find">Total de {{this.paginationConfig.totalItems}} documentos encontrados</span>
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-primary float-right" (click)="newDocumentFile()">
                        Subir Archivo
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from './constants';
import { PersonRequest } from '../model/person-request';

@Injectable({
  providedIn: 'root'
})
export class ErpPersonService {

  url = "erp/person";

  constructor(private _httpClient: HttpClient){    
  }

  public getPersonList (filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url , filter);
  }

  public updatePersonList (filter: PersonRequest): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/update' , filter);
  }

  public deletePersonList (filter: PersonRequest): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/delete' , filter);
  }


}

import { Injectable } from '@angular/core';
import { UserManager, User} from 'oidc-client';
import { Constants } from './constants';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthContext } from '../model/auth-context';
import { UserContext } from '../model/user-context';
import { UserPermission } from '../model/user-permission';

@Injectable()
export class AuthService {

    private _userContext: UserContext;
    private _userManager: UserManager;

    private _loginChangedSubject = new Subject<boolean>();
    private _userContextChanged = new Subject<UserContext>();

    // public and observable properties
    public loginChanges() {
        return this._loginChangedSubject.asObservable()
    };

    public userChangedContext(){
        return this._userContextChanged.asObservable();
    };

    constructor(private _httpClient: HttpClient) {
        const stsSettings = {
            authority: Constants.stsAuthority,
            client_id: Constants.clientId,
            redirect_uri: `${Constants.clientRoot}signin-callback`,
            scope: Constants.clientId_scope,
            response_type: Constants.clientId_response_type,
            post_logout_redirect_uri: `${Constants.clientRoot}signout-callback`,
            automaticSilentRenew: true,
            silent_redirect_uri: `${Constants.clientRoot}assets/silent-callback.html`
        };
        this._userManager = new UserManager(stsSettings);

        this._userManager.events.addAccessTokenExpired(() => {
            this.clearStorage();
            this._loginChangedSubject.next(false);
        });

        this._userManager.events.addUserLoaded(user => {
          if (this._userContext && this._userContext.user && this._userContext.user !== user) {
            this.loadSecurityContext(user);
            this._loginChangedSubject.next(!!user && !user.expired);
          }
        });
    }

    clearStorage() {
        localStorage.clear();
        sessionStorage.clear();
      }

    login(){
        return this._userManager.signinRedirect();
    }

    isLoggedIn(): Promise<boolean>{
        return this._userManager.getUser().then(user => {
            const userCurrent = !!user && !user.expired;        // user not expired
            if (this._userContext.user !== user) {
                this._loginChangedSubject.next(userCurrent);
            }
            if (userCurrent && !this._userContext){
                this.loadSecurityContext(user);
            }
            return userCurrent
        })
    }

    completeLogin(){
        return this._userManager.signinRedirectCallback().then(user => {
            this._loginChangedSubject.next(!!user && !user.expired);
            this.loadSecurityContext(user);
            return user;
        })
    }

    logout() {
        // return to STS to invalidate the token and end session
        this._userManager.signoutRedirect().then();
    }

    completeLogout(){
        this._userContext = null;
        return this._userManager.signoutRedirectCallback();
    }

    getAccessToken(){
        return this._userManager.getUser().then(user =>{
            if (!!user && !user.expired){
                return user.access_token;
            }
            return null;
        })
    }

    loadSecurityContext(userFromUserManager: User){
        const promise =this._httpClient
            .get<AuthContext>(`${Constants.apiRoot}access/authContext`).toPromise();

        promise.then(context => {
            this._userContext = new UserContext();
            this._userContext.user = userFromUserManager;
            this._userContext.auth = new AuthContext();
            this._userContext.auth.claims = context.claims;
            this._userContext.auth.userPermissions = context.userPermissions;
            this._userContextChanged.next(this._userContext);
        }).catch(error => {
            console.error(error)
        })
    }

    getUser(){
        if (!!this._userContext && !!this._userContext.user && !this._userContext.user.expired) {
            return this._userContext.user;
        }
        return null;
    }

    getAuthPermissions(): UserPermission{
        if (!!this._userContext && !!this._userContext.user && !this._userContext.user.expired && !!this._userContext.auth) {
          return this._userContext.auth.userPermissions[0];   // positions after "0" are for future use
        }
        return null;
    }

}

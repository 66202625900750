<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

<div class="modal-body">
  <form [formGroup]="documentUploadForm" id="documentUploadForm" class="form-group">
    <div class="row">
      <div class="col-md-10 text-left pl-0">
      </div>
      <div class="col-md-2">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
    <div class="form-group row mt-2">
      <label class="col-sm-2 col-form-label mb-0">Nombre:</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" formControlName="nombre" [ngClass]="{ 'is-valid': documentUploadForm.controls.nombre.valid, 
          'is-invalid': (documentUploadForm.controls.nombre.dirty || documentUploadForm.controls.nombre.touched ) && documentUploadForm.controls.nombre.invalid}" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label mb-0">Archivo:</label>
      <div class="col-sm-10">
        <button class="btn-primary btn-sm" type="button" (click)="fileUpload.click()" [disabled]='isUpdate'>Examinar</button>
        <input type="file" #fileUpload onclick="this.value = null" (change)="uploadPdf($event.target.files)" accept="application/pdf" hidden>
        <p> {{ fileName }} </p>
      </div>
    </div>    
    <div class="form-group row">
      <label class="col-sm-2 col-form-label mb-0">Descripción:</label>
      <div class="col-sm-10">
        <textarea class="form-control" formControlName="description"></textarea>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label mb-0">Fecha:</label>
      <div class="col-sm-10">
        <input [owlDateTime]="fechadate" [owlDateTimeTrigger]="fechadate" type="text"
          class="form-control form-control-sm date-field w-100" formControlName="fecha" [ngClass]="{ 'is-valid': documentUploadForm.controls.fecha.valid, 
          'is-invalid': (documentUploadForm.controls.fecha.dirty || documentUploadForm.controls.fecha.touched ) && documentUploadForm.controls.fecha.invalid}" />
        <owl-date-time #fechadate [firstDayOfWeek]="1" [pickerType]="'calendar'"></owl-date-time>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label mb-0">Tipo de Archivo:</label>
      <div class="col-sm-9">
      <ng-select #selectDocType (close)="selectDocType.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
        [items]="archivoList" id="filter-archivo" name="filter-archivo" [clearable]="true" [virtualScroll]="true"
        bindLabel="label" bindValue="value" formControlName="archivo" placeholder="Tipo de Archivo"
        (keyup)="onKeyUpTipo($event)" (scrollToEnd)="onScrollTipo()" (clear)="onCloseTipo()" 
        [ngClass]="{'border-danger': !documentUploadForm.get('archivo').valid && documentUploadForm.get('archivo').touched }">
      </ng-select>    
      <span class="text-danger"
        *ngIf="!documentUploadForm.get('archivo').valid && documentUploadForm.get('archivo').touched">
        Ingresar valor
      </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2 text-right mt-2">
        <button class="btn btn-danger mr-3" (click)="deleteDocument()" *ngIf="isEliminar" >Eliminar</button>
      </div>

      <div class="col-lg-10 col-xl-10 col-md-10 col-sm-10 text-right mt-2">
        <button class="btn btn-primary" type="button" [disabled]="!documentUploadForm.valid" (click)="generateDocument()">Guardar</button>
    </div>
    </div>
  </form>
</div>